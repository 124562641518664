<template>
	<div>
		<div class="bg-white" style="min-height: 88vh;">
			<div class="padding stu-info">
				<border-title titleName="课程基础信息" />
			</div>
			<el-form :model="basicForm" :rules="basicRules" ref="basicForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="课程名称" prop="curriculum_name">
					<el-input v-model="basicForm.curriculum_name" placeholder="请输入课程名称" style="width: 42%;"></el-input>
					<el-tooltip class="item margin-left-sm" effect="dark">
						<i class="el-icon-question text-blue" />
						<div slot="content">课程名称中建议包含年份、年级等信息，方便区分例如：“2019年六年级语文课”、“2019年暑期基础绘画课”</div>
					</el-tooltip>
				</el-form-item>
				<el-form-item label="课程类别" prop="curriculum_type" style="width: 50%;">
					<el-select v-model="basicForm.curriculum_type" filterable placeholder="请选择课程类别"
						@focus="getCourseClass">
						<el-option v-for="item in courseClassList" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
					<el-button type="primary" size="mini" style="margin-left: 15px;" @click="editHandle(1)">新建类别
					</el-button>
					<el-tooltip class="item margin-left-sm" effect="dark">
						<i class="el-icon-question text-blue" />
						<div slot="content">对课程分类，便于后期统计分析，例如“小学课程”、“初中课程”</div>
					</el-tooltip>
				</el-form-item>
				<el-form-item label="科目" prop="curriculum_subject" style="width: 50%;">
					<el-select v-model="basicForm.curriculum_subject" multiple placeholder="请选择科目"
						@focus="getSubjectTypeList">
						<el-option v-for="item in SubjectTypeList" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
					<el-button type="primary" size="mini" style="margin-left: 15px;" @click="editHandle(2)">新建科目
					</el-button>
					<el-tooltip class="item margin-left-sm" effect="dark">
						<i class="el-icon-question text-blue" />
						<div slot="content">类似“课程标签”，便于后期统计分析，例如“数学”、“绘画”</div>
					</el-tooltip>
				</el-form-item>
				<el-form-item label="学季" prop="season ">
					<el-select v-model="basicForm.season" filterable placeholder="请选择学季">
						<el-option v-for="item in fourSeasons" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="授课模式">
					<el-radio-group v-model="basicForm.tmode">
						<el-radio :label="0">班课</el-radio>
						<el-radio :label="1">一对一</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="开课校区">
					<el-radio-group v-model="school_id" @change="schoolChange">
						<el-radio :label="0">全部校区</el-radio>
						<el-radio :label="1">指定校区</el-radio>
					</el-radio-group>
					<div class="margin-left-50 bg-z padding-lr" style="width: 45%;" v-if="isAllSchool">
						<el-checkbox-group v-model="basicForm.openschool_id" @change="schoolListChange">
							<el-checkbox v-for="school in schoolList" :key="school.id" :label="school.id"
								:value="school.id">{{ school.name }}</el-checkbox>
						</el-checkbox-group>
					</div>
				</el-form-item>
			</el-form>

			<div class="padding stu-info margin-top-xl">
				<border-title titleName="收费模式" />
			</div>
			<!--按课时收费 -->
			<el-form ref="ruleForm" class="demo-ruleForm">
				<el-form-item label="按课时收费" label-width="100px">
					<el-switch v-model="isClassroom" @change="classroomChange"></el-switch>
					<el-tooltip class="item margin-left-sm" effect="dark">
						<i class="el-icon-question text-blue" />
						<div slot="content">适合按上课次数或课时包售卖的课程</div>
					</el-tooltip>
				</el-form-item>
				<el-table v-if="isClassroom" :data="chargeForm" border style="width: 60%;margin-left: 100px;"
					:span-method="objectSpanMethod">
					<el-table-column prop="school_name" label="开课校区" width="180"></el-table-column>
					<el-table-column prop="num" label="数量(课时)" width="180">
						<template slot-scope="scope">
							<!-- <el-form-item label="" :prop="`chargeForm.$(scope.$index).num`"> -->
								<el-input-number v-model="scope.row.num" controls-position="right" :min="1"
									style="width: 100px;" />
							<!-- </el-form-item> -->
						</template>
					</el-table-column>
					<el-table-column prop="money" label="总价(元)" width="180">
						<template slot-scope="scope">
							<!-- <el-form-item prop="`chargeForm.$(scope.$index).money`"> -->
								<el-input-number type="number" v-model="scope.row.money" controls-position="right"
									:min="0" style="width: 100px;" />
							<!-- </el-form-item> -->
						</template>
					</el-table-column>
					<el-table-column align="center" label="操作">
						<template slot-scope="scope">
							<i v-if="scope.$index > 0" class="el-icon-circle-close lines-grey margin-right cursor"
								@click="delForm(scope.$index)" />
							<i class="el-icon-circle-plus-outline lines-blue cursor"
								@click="AddForm(scope.row.school_id)" />
						</template>
					</el-table-column>
				</el-table>
			</el-form>
			<!--按时间收费 -->
			<el-form ref="chargeForm" class="demo-ruleForm">
				<el-form-item label="按时间收费" label-width="100px">
					<el-switch v-model="isTime" @change="timeChange"></el-switch>
					<el-tooltip class="item margin-left-sm" effect="dark">
						<i class="el-icon-question text-blue" />
						<div slot="content">适合按时间段售卖的课程，例如：月卡、季卡、年卡</div>
					</el-tooltip>
				</el-form-item>
				<el-table v-if="isTime" :data="chargeForm" border style="width: 60%;margin-left: 100px;"
					:span-method="objectSpanMethod">
					<el-table-column prop="school_name" label="开课校区" width="180"></el-table-column>
					<el-table-column prop="num" label="数量(课时)">
						<template slot-scope="scope">
							<el-form-item label="" :prop="`chargeForm.$(scope.$index).num`"
								:rules="periodChargFormRules.num">
								<el-input-number v-model="scope.row.num" controls-position="right" :min="1"
									style="width: 100px;" />
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column label="时间段">
						<template slot-scope="scope">
							<el-select v-model="scope.row.time_slot" placeholder="请选择"
								style="width: 100px;margin-bottom: 22px;">
								<el-option v-for="item in dateOptions" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column prop="money" label="总价(元)">
						<template slot-scope="scope">
							<el-form-item prop="`chargeForm.$(scope.$index).money`" :rules="periodChargFormRules.money">
								<el-input-number type="number" v-model="scope.row.money" controls-position="right"
									:min="0" style="width: 100px;" />
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column align="center" label="操作">
						<template slot-scope="scope">
							<i v-if="scope.$index > 0" class="el-icon-circle-close lines-grey margin-right cursor"
								@click="delForm(scope.$index)" />
							<i class="el-icon-circle-plus-outline lines-blue cursor"
								@click="AddForm(scope.row.school_id)" />
						</template>
					</el-table-column>
				</el-table>
			</el-form>
			<el-form class="demo-ruleForm">
				<el-form-item label="按期收费" label-width="100px">
					<el-switch v-model="isPeriod" @change="periodChange"></el-switch>
					<el-tooltip class="item margin-left-sm" effect="dark">
						<i class="el-icon-question text-blue" />
						<div slot="content">适合按期售卖的课程（不允许续费），例如：春季班、暑假班、寒假班</div>
					</el-tooltip>
				</el-form-item>

				<el-table v-if="isPeriod" :data="chargeForm" border style="width: 50%;margin-left: 100px;"
					:span-method="objectSpanMethod">
					<el-table-column prop="school_name" label="开课校区" width="180"></el-table-column>
					<el-table-column label="数量(课时)">
						<template slot-scope="scope">
							<el-form-item label="" :prop="`chargeForm.$(scope.$index).num`"
								:rules="periodChargFormRules.num">
								<el-input-number v-model="scope.row.num" controls-position="right" :min="1"
									style="width: 100px;" />
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column label="总价(元)">
						<template slot-scope="scope">
							<el-form-item prop="`chargeForm.$(scope.$index).money`" :rules="periodChargFormRules.money">
								<el-input-number type="number" v-model="scope.row.money" controls-position="right"
									:min="0" style="width: 100px;" />
							</el-form-item>
						</template>
					</el-table-column>
				</el-table>
			</el-form>

			<div class="padding stu-info margin-top-xl">
				<border-title titleName="其他信息" />
			</div>
			<el-form :model="basicForm" :rules="chargeRules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="教材杂费" prop="region">
					<el-button size="mini" disabled>添加</el-button>
				</el-form-item>
				<el-form-item label="备注" prop="remarks ">
					<el-input type="textarea" placeholder="最多可输入300字" max="200" v-model="basicForm.remarks"
						style="width: 500px;" />
				</el-form-item>
			</el-form>

			<div class=" margin-left-xl   triangle-topleft">
				<el-button type="primary" @click="submite">确定</el-button>
			</div>
		</div>

		<newCategory :Visible="editVisible && editType === 1" @closepop="closeEditPop"></newCategory>
		<newSubjects :Visible="editVisible && editType === 2" @closepop="closeEditPop"></newSubjects>
	</div>
</template>

<script>
	import BorderTitle from '@/components/border-title/index.vue';
	import newCategory from '../components/newCategory.vue';
	import newSubjects from '../components/newSubjects.vue';
	import {
		fourSeasons
	} from '@/config/index';
	export default {
		components: {
			BorderTitle,
			newCategory,
			newSubjects
		},
		data() {
			return {
				fourSeasons,
				basicForm: {
					curriculum_name: '',
					curriculum_type: '',
					curriculum_subject: [],
					charge_type: 0,
					season: '',
					tmode: 0,
					openschool_id: [],
          remarks: ''
				},
				basicRules: {
					curriculum_name: [{
							required: true,
							message: '请输入课程名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 20,
							message: '长度在 1 到 20 个字符',
							trigger: 'blur'
						}
					],
					curriculum_type: [{
						required: true,
						message: '请选择课程类别',
						trigger: 'blur'
					}]
				},
				school_id: 0,
				isAllSchool: false,
				schoolList: [], // 获取的校区列表
				checkList: [], // 选中校区

				charge_type: 0,
				isClassroom: true,
				isTime: false,
				isPeriod: false,
				dateOptions: [{
						value: 0,
						label: '天'
					},
					{
						value: 1,
						label: '月'
					},
					{
						value: 2,
						label: '季'
					},
					{
						value: 3,
						label: '半年'
					},
					{
						value: 4,
						label: '年'
					}
				],
				chargeRules: {
					classroomChargForm: {},
					timeChargForm: {}
				},
				periodChargFormRules: {
					num: [{
						required: false,
						message: '请填写数量',
						trigger: ['blur', 'change']
					}],
					money: [{
						required: false,
						message: '请填写总价',
						trigger: ['blur', 'change']
					}]
				},
				chargeForm: [{
					charge_type: 1,
					num: '1',
					school_id: '',
					school_name: '全部校区',
					money: '0',
					time_slot: 1
				}],
				restChargeForm: [{
					charge_type: 1,
					num: '1',
					school_id: '',
					school_name: '全部校区',
					money: '',
					time_slot: '1'
				}],
				number1: 1,
				editVisible: false,
				editType: 1,
				restaurants: [],
				courseClassList: [], //课程类别
				SubjectTypeList: [], //科目
				value: 0,
				listQuery: {
					HasRole: false,
					module: '',
					TotalCount: 0,
					PageIndex: 1,
					PageSize: 10
				},
				tableData: [{
					campus: '全部校区',
					number: '1',
					totalPrice: '20'
				}],
				ruleForm: {
					curriculum_name: '',
					curriculum_type: '',
					classCount: '',
					region: '',
					date1: '',
					date2: '',
					desc: '',
					num1: '1',
					num2: '1',
					num: 1,
					resource: '',
					resource1: '',
					classroom: true,
					time: true,
					period: true,
					number: '',
          remarks: ''
				},
				editData: {}
			};
		},
		created() {
			this.getCourseClass();
			this.getSubjectTypeList();
			this.getAllSchool();
			// Promise.all([]).then(res => {
			//   // console.log(res);
			//   // this.schoolList = res
			//   console.log('this.schoolList', this.schoolList);

			// });
		},
		watch: {
			// schoolList: {
			//   deep: true,
			//   handler(val) {
			//     console.log('watch', val);
			//   }
			// },
			chargeForm: {
				deep: true,
				handler(val) {
					if (val.length > 1) {
						let num = val[val.length - 1].num;
						let arr = val
						arr = arr.slice(arr.length - 2, arr.length - 1)
						if (arr.findIndex(t => t.num == num) > -1) this.$message('课时数不能相同');
					}
				}
			}
		},
		mounted() {
			if (this.$route.params.id) this.getClassDetail(this.$route.params.id);
		},
		methods: {
			// 获取单个课程信息
			getClassDetail(id) {
				this.API.getClassDetail(id).then(res => {
					console.log(res)
					let arr = res.data;
					arr.openschool_id = [];
					arr.curriculum_subject = arr.curriculum_subjectid;
					if (arr.charge_type == 1) {
						this.isClassroom = true;
						this.isTime = false;
						this.isPeriod = false;
					} else if (arr.charge_type == 2) {
						this.isClassroom = false;
						this.isTime = true;
						this.isPeriod = false;
					} else if (arr.charge_type == 3) {
						this.isClassroom = false;
						this.isTime = false;
						this.isPeriod = true;
					}

					if (arr.open_school != '0' && arr.open_school.indexOf(',') != -1) {
						// 为指定校区
						arr.open_school = arr.open_school.split(',');
						this.school_id = 1;
						this.isAllSchool = true;
						arr.openschool_id = arr.open_school;
						this.$forceUpdate();
					} else if (arr.open_school == '0') {
						// 为全部校区
						this.school_id = 0;
						arr.openschool_id = [0];
					} else {
						this.school_id = 1;
						this.isAllSchool = true;
						arr.openschool_id.push(arr.open_school * 1);
					}
					
					this.basicForm = arr;
					let _this = this;
					console.log(_this.schoolList);
					_this.schoolList &&
						arr.listm.map(t => {
							let index = _this.schoolList.findIndex(i => t.school_id == i.id);
							if(index>0){
								t.school_name = _this.schoolList[index].name;
							}
							else{
								t.school_name="全部校区"
							}
						});
					_this.chargeForm = arr.listm;
					_this.$forceUpdate();
				});
			},
			editHandle(type) {
				this.editVisible = true;
				this.editType = type;
			},
			getCourseClass(e) {
				this.listQuery.module = 'course_class';
				this.$Dictionary.GetDictionarys(this.listQuery).then(res => {
					this.courseClassList = res.data.rows;
					this.$forceUpdate();
				});
			},
			getSubjectTypeList() {
				this.listQuery.module = 'SubjectType';
				this.$Dictionary.GetDictionarys(this.listQuery).then(res => {
					this.SubjectTypeList = res.data.rows;
					this.$forceUpdate();
				});
			},
			// 获取全部校区
			getAllSchool() {
				let obj = {
					type: 1,
					KeyWord: '',
					PageIndex: 1,
					PageSize: 10,
					Sort: []
				};
				this.API.OrganizationList(obj).then(res => {
					this.schoolList = res.data.rows;
				});
			},
			handleSelect(item) {},
			schoolChange(e) {
				// this.school_id = e;
				this.isAllSchool = !this.isAllSchool;
				this.basicForm.openschool_id = [];
				let _this = this;
				if (e == 0) {
					_this.school_id = e;
					_this.isClassroom = true;
					this.chargeForm = [{
						charge_type: 1,
						num: '1',
						school_id: '',
						school_name: '全部校区',
						money: '0',
						time_slot: 1
					}];
				} else {
					_this.school_id = e;
					_this.isClassroom = false;
					this.chargeForm = [];
					// this.isAllSchool = true;
				}
			},
			// 选择校区
			schoolListChange(e) {
				this.chargeForm = [];
				if (e.length == 0) {
					this.isClassroom = false;
					this.isTime = false;
					this.isPeriod = false;
					this.chargeForm = [];
				}
				if (e.length > 0 && this.isTime == false && this.isPeriod == false) this.isClassroom = true;

				let list = this.schoolList
					.filter(t => e.findIndex(item => item === t.id) > -1)
					.map(item => {
						return {
							charge_type: 0,
							num: 0,
							school_id: item.id,
							school_name: item.name,
							money: '0',
							time_slot: 1
						};
					});
				list.forEach(item => {
					if (this.chargeForm.findIndex(t => t.school_id === item.id) === -1) {
						this.chargeForm.push(item);
					}
				});
			},
			// 收费模式选择
			classroomChange() {
				if (this.school_id == 1 && this.chargeForm.length == 0 && this.checkList.length == 0) {
					this.$message('请先选择校区');
					this.isClassroom = false;
					return;
				}
				this.isTime = false;
				this.isPeriod = false;
			},
			timeChange() {
				if (this.school_id == 1 && this.chargeForm.length == 0 && this.checkList.length == 0) {
					this.$message('请先选择校区');
					this.isTime = false;
					return;
				}
				this.isClassroom = false;
				this.isPeriod = false;
			},
			periodChange() {
				if (this.school_id == 1 && this.chargeForm.length == 0 && this.checkList.length == 0) {
					this.$message('请先选择校区');
					this.isPeriod = false;
					return;
				}
				this.isClassroom = false;
				this.isTime = false;
			},
			AddForm(e) {
				let chargeType;
				let index = this.chargeForm.findIndex(t => t.school_id === e);
				if (this.isClassroom) {
					chargeType = 1;
				} else {
					chargeType = this.isTime ? 2 : 3;
				}
				let obj = {
					charge_type: chargeType,
					num: 0,
					school_name: this.chargeForm[index].school_name,
					school_id: e,
					money: '0',
					time_slot: 1
				};
				this.chargeForm.splice(index, 0, obj);
			},
			delForm(e) {
				// console.log(e);
				this.chargeForm.splice(e, 1);
			},
			// 合并表格
			objectSpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				let list = this.chargeForm;
				let len = list.filter(t => t.school_id === row.school_id).length;
				if (columnIndex !== 0) {
					return [1, 1];
				} else {
					if (len > 1 && list.findIndex(t => t.school_id == row.school_id) !== rowIndex) {
						return [0, 0];
					} else {
						return [len, 1];
					}
				}
			},
			submite() {
				if (this.chargeForm.findIndex(t => t.money == 0) > -1) {
					this.$message('总价不能为0');
					return;
				}
				let arr = this.basicForm;
				let list;
				if (this.isClassroom)(list = this.chargeForm), (arr.charge_type = 1);
				if (this.isTime)(list = this.chargeForm), (arr.charge_type = 2);
				if (this.isPeriod)(list = this.chargeForm), (arr.charge_type = 3);
				if (this.school_id == 0) {
					list.map(t => (t.school_id = 0));
					arr.openschool_id = '0';
				} else {
					if (arr.openschool_id.length > 1) {
						arr.openschool_id = arr.openschool_id.join(',');
					} else {
						arr.openschool_id = arr.openschool_id.toString();
					}
				}

				let obj = {
					curriculum_name: '',
					curriculum_type: 0,
					curriculum_subject: [0],
					charge_type: 0,
					season: 0,
					tmode: 0,
					school_id: [],
					mlist: [
						// {
						//   charge_type: 1,
						//   num: 0,
						//   school_id: 0,
						//   money: 0,
						//   time_slot: 0
						// }
					],
					inlist: [
						// {
						//   incidentalsid: '',
						//   num: ''
						// }
					],
					remarks: ''
				};
				obj = Object.assign({}, obj, this.basicForm);
				list.map(t => {
					t.charge_type = obj.charge_type
					return t
				})
				obj.mlist = list;

				console.log(JSON.stringify(obj));
				this.API.addCurriculum(obj).then(res => {
					if (res.success) {
						this.$message.success(res.message);
						this.$router.go(-1);
					} else {
						this.$message.error(res.message);
					}
				});
			}
		}
	};
</script>

<style></style>
